import { Router, scrollBehavior } from '@/utils/router.js';
import { getTokenFromCookie } from '@/utils/cookies';
import { hostBuild, setHtmlMeta } from '@/utils/utils';

var router = new Router({
  mode: 'history',
  routes: [

    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName: "core_home" */ './home/home.vue'),
      meta: { desc: '首页', isAuth: true },
    },
    {
      path: '/home/:id(\\w{8}-\\w{4}-\\w{4}-\\w{4}-\\w{12})',
      name: 'HisHome',
      component: () => import(/* webpackChunkName: "core_home" */ './his-home/his-home.vue'),
      meta: { desc: '他的主页' },
    },
    {
      // query.to 用户ID
      path: '/chat',
      name: 'ChatHome',
      component: () => import(/* webpackChunkName: "core_home" */ './chat-home/chat-home.vue'),
      meta: { desc: '私信', isAuth: true },
    },
    {
      path: '/simulate/:code',
      name: 'Simulate',
      component: () => import(/* webpackChunkName: "core_home" */ './simulate/simulate.vue'),
      meta: { desc: '模拟账号' },
    },
    {
      path:'/popularize',
      name:'PopularizeFrame',
      component: () => import(/* webpackChunkName: "popularize_pay" */ './components/frame/popularizeFrame.vue'),
      children:[
        {
          path: 'promotion/:system/:id',
          name: 'PromotionCreate',
          component: () => import(/* webpackChunkName: "core_home" */ './promotion/create.vue'),
          meta: { desc: '推广', isAuth: true },
        },
        {
          // :id 订单号
          path: 'pay/:id',
          name: 'Pay',
          component: () => import(/* webpackChunkName: "core_home" */ './pay/pay.vue'),
          meta: { desc: '支付页' },
        },
        {
          // :id 订单号
          path: 'success/:id',
          name: 'PaySuccess',
          component: () => import(/* webpackChunkName: "core_home" */ './pay/success.vue'),
          meta: { desc: '支付成功' },
        },
        {
          // :id 订单状态
          path: 'message/:id',
          name: 'PayMessage',
          component: () => import(/* webpackChunkName: "core_home" */ './pay/message.vue'),
          meta: { desc: '订单状态' },
        },
      ]
    },
    {
      path: '',
      component: () => import(/* webpackChunkName: "core_home" */ './components/frame/frame.vue'),
      children: [
        
        {
          path: '/wallet',
          name: 'Wallet',
          component: () => import(/* webpackChunkName: "core_home" */ './wallet/main.vue'),
          meta: { desc: '钱包', isAuth: true },
        },
        {
          path: '/wallet/setting',
          name: 'WalletSetting',
          component: () => import(/* webpackChunkName: "core_home" */ './wallet/setting.vue'),
          meta: { desc: '钱包设置', isAuth: true },
        },
        {
          path: '/order',
          name: 'Order',
          component: () => import(/* webpackChunkName: "core_home" */ './order/order.vue'),
          meta: { desc: '我的订单', isAuth: true },
        },
        {
          path: '/center/post',
          name: 'CenterPost',
          redirect:'/center/information'
        },
        {
          path: '/center/information',
          name: 'CenterInformation',
          component: () => import( './center-post/center-post.vue'),
          meta: { desc: '我的发布', isAuth: true },
        },
        {
          path: '/center/info',
          name: 'CenterInfo',
          component: () => import(/* webpackChunkName: "core_home" */ './center-info/center-info.vue'),
          meta: { desc: '我的黄页', isAuth: true },
        },
        {
          path: '/center/ad',
          name: 'CenterAd',
          component: () => import(/* webpackChunkName: "core_home" */ './center-ad/center-ad.vue'),
          meta: { desc: '我的广告', isAuth: true },
        },
        {
          path: '/center/comment',
          name: 'CenterComment',
          component: () => import(/* webpackChunkName: "core_home" */ './center-comment/center-comment.vue'),
          meta: { desc: '我的评论', isAuth: true },
        },
        {
          path: '/center/collect',
          name: 'CenterCollect',
          component: () => import(/* webpackChunkName: "core_home" */ './center-collect/center-collect.vue'),
          meta: { desc: '我的收藏', isAuth: true },
        },
        {
          path: '/center/footprint',
          name: 'CenterFootprint',
          component: () => import(/* webpackChunkName: "core_home" */ './center-footprint/center-footprint.vue'),
          meta: { desc: '我的足迹', isAuth: true },
        },
        {
          path: '/center/fan',
          name: 'CenterFan',
          component: () => import(/* webpackChunkName: "core_home" */ './center-fan/center-fan.vue'),
          meta: { desc: '关注/粉丝', isAuth: true },
        },
        {
          path: '/center/personal',
          name: 'CenterPersonal',
          component: () => import(/* webpackChunkName: "core_home" */ './center-personal/center-personal.vue'),
          meta: { desc: '个人资料', isAuth: true },
        },
        {
          path: '/center/account',
          name: 'CenterAccount',
          component: () => import(/* webpackChunkName: "core_home" */ './center-account/center-account.vue'),
          meta: { desc: '账户密码', isAuth: true },
        },
        {
          path: '/center/shield',
          name: 'CenterShield',
          component: () => import(/* webpackChunkName: "core_home" */ './center-shield/center-shield.vue'),
          meta: { desc: '黑名单', isAuth: true },
        },
        {
          path: '/center/notify',
          name: 'CenterNotify',
          component: () => import(/* webpackChunkName: "core_home" */ './center-notify/center-notify.vue'),
          meta: { desc: '通知设置', isAuth: true },
        },
        {
          path: '/center/privacy',
          name: 'CenterPrivacy',
          component: () => import(/* webpackChunkName: "core_home" */ './center-privacy/center-privacy.vue'),
          meta: { desc: '通知设置', isAuth: true },
        },
        {
          path: '/center/signature',
          name: 'CenterSignature',
          component: () => import(/* webpackChunkName: "core_home" */ './center-signature/center-signature.vue'),
          meta: { desc: '签名设置', isAuth: true },
        },
        {
          path: '/notice',
          name: 'NoticeHome',
          component: () => import(/* webpackChunkName: "core_home" */ './notice-home/notice-home.vue'),
          meta: { desc: '通知', isAuth: true },
        },
      ],
    },
    {
      path: '/websafe',
      name: 'WebSafe',
      component: () => import(/* webpackChunkName: "core_home" */ '@/common/websafe.vue'),
    },
    {
      path: '/404',
      name: 'NotFound',
      component: () => import(/* webpackChunkName: "core_home" */ '@/common/404/index.vue'),
      meta: { desc: '404' },
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
  scrollBehavior,
});

router.beforeEach((to, from, next) => {
  // 这里只能用本地token进行判断是否登录，否则刷新页面会出现问题
  const token = getTokenFromCookie();
  if (to.meta.isAuth === true && !token) {
    location.replace(hostBuild(`/login?redirect=${location.href}`, 'account'));
    return next(false);
  }

  if (to.name == 'Home') {
    setHtmlMeta({
      title: '用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'CenterHome') {
    setHtmlMeta({
      title: '用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'CenterPost') {
    setHtmlMeta({
      title: '我的帖子 - 用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'CenterInformation') {
    setHtmlMeta({
      title: '我的发布 - 用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'CenterInfo') {
    setHtmlMeta({
      title: '我的黄页 - 用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'CenterComment') {
    setHtmlMeta({
      title: '我的评论 - 用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'CenterCollect') {
    setHtmlMeta({
      title: '我的收藏 - 用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'CenterFootprint') {
    setHtmlMeta({
      title: '我的足迹 - 用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'CenterFan') {
    setHtmlMeta({
      title: '关注/粉丝 - 用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'CenterPersonal') {
    setHtmlMeta({
      title: '个人资料设置 - 用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'CenterSignature') {
    setHtmlMeta({
      title: '签名设置 - 用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'CenterAccount') {
    setHtmlMeta({
      title: '账户密码设置 - 用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'CenterShield') {
    setHtmlMeta({
      title: '黑名单设置 - 用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'CenterNotify') {
    setHtmlMeta({
      title: '通知设置 - 用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'NoticeHome') {
    setHtmlMeta({
      title: '我的通知 - 用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'ChatHome') {
    setHtmlMeta({
      title: '私信 - 用户中心 - 约克论坛',
      keywords: '',
      description: '',
    });
  } else if (to.name == 'WebSafe') {
    setHtmlMeta({ title: '外链 - 约克论坛', keywords: '', description: '' });
  } else if (to.name == 'NotFound') {
    setHtmlMeta({ title: '404 - 约克论坛', keywords: '', description: '' });
  } else {
    setHtmlMeta({ title: '', keywords: '', description: '' });
  }

  if (to.name == 'NotFound' && !to.query.from) return next({ name: 'NotFound', query: { from: location.href } });

  next();
});

export { router };
