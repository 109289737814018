import Vue from 'vue';
import Vuex from 'vuex';
import { commonStore } from '@/utils/store.js';
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    ...commonStore.state,
    // 信息类别
    _postType: {
      bbs: { name: '论坛', domain: 'forum' },
      car: { name: '汽车', domain: 'car' },
      house: { name: '房产', domain: 'house' },
      forsale: { name: '二手', domain: 'forsale' },
      job: { name: '招聘', domain: 'job' },
      cms: { name: '新闻', domain: 'news' },
      info: { name: '黄页', domain: 'info' },
    },
  },
  getters: {
    ...commonStore.getters,
    postType(state) {
      return state._postType;
    },
  },
  mutations: {
    ...commonStore.mutations,
  },
  actions: {
    ...commonStore.actions,
  },
});

export { store };
